import dayjs from "dayjs"
import { ClockCircleOutlined, StopOutlined } from "@ant-design/icons"
import { generateResult } from "../helpers/generateResult"

const handleExpiring = (enrolment) => {
  if (enrolment?.currentState !== "completed") return null
  if (!enrolment?.completionValidUntil) return null
  const difference = dayjs(enrolment?.completionValidUntil).diff(dayjs(), "days")
  if (difference > 0) {
    if (difference > 365) {
      return {
        value: `Expiring in over a year`,
        status: "Expiring",
        icon: <ClockCircleOutlined />,
      }
    } else {
      return {
        value: `Expiring ${dayjs().to(enrolment?.completionValidUntil)}`,
        status: "Expiring",
        icon: <ClockCircleOutlined />,
      }
    }
  } else if (difference < 0) {
    return {
      value: `Expired ${dayjs(enrolment?.completionValidUntil).from(dayjs())}`,
      status: "Expired",
      icon: <StopOutlined />,
    }
  }
}

function ernolmentFormatter(enrolments) {
  return enrolments.map((enrl) => ({
    key: enrl?.uid,
    enrolment: {
      title: enrl.publication.content.title,
      src: enrl.publication.featureImageUrl,
      uid: enrl.uid,
      type: enrl.publication.type?.replaceAll("-", " ")?.toSentenceCase(),
      contentType: enrl.publication.contentType,
    },
    result:
      enrl?.currentState === "completed" && enrl?.publication?.type === "course"
        ? generateResult[enrl?.publication?.type](enrl)
        : null,
    type: enrl.publication?.isFacilitated
      ? [enrl.publication.type?.replaceAll("-", " ")?.toSentenceCase()]
      : // ? [enrl.publication.type?.replaceAll("-", " ")?.toSentenceCase(), "In Person"]
        [enrl.publication.type?.replaceAll("-", " ")?.toSentenceCase()],
    status: [
      {
        status: enrl.currentState?.replaceAll("-", " ")?.toSentenceCase(),
        value: enrl.currentState?.replaceAll("-", " ")?.toSentenceCase(),
      },
      handleExpiring(enrl),
      (() => {
        if (enrl?.completedStatus) {
          return {
            status: enrl?.completedStatus,
            value: enrl?.completedStatus,
          }
        }
      })(),
    ],
    certificate: enrl?.currentState === "completed" && enrl?.completedStatus === "passed" ? enrl : null,
  }))
}

function tableSorter(enrolments, key, value) {
  return ernolmentFormatter(value && value !== "all" ? [...enrolments?.filter((x) => x[key] === value)] : enrolments)
}

export function profileReducer(state, action) {
  const { type, field, payload } = action
  switch (type) {
    case "initialize": {
      return {
        ...state,
        tableData: ernolmentFormatter(payload),
        enrolments: payload,
        initialized: true,
      }
    }
    case "sort": {
      return {
        ...state,
        tableData: tableSorter(state.enrolments, "currentState", payload),
        sort: payload,
      }
    }
    case "search": {
      return {
        ...state,
        tableData:
          payload !== ""
            ? state?.tableData?.filter((x) => x?.enrolment?.title.toLowerCase().includes(payload.toLowerCase()))
            : tableSorter(state?.enrolments, "currentState", state?.sort),
      }
    }
    case "page": {
      let offset = state?.filters?.limit * payload - state?.filters?.limit
      if (state?.total < offset) {
        offset -= offset - state?.total
      }
      return {
        ...state,
        page: payload,
        filters: {
          ...state.filters,
          offset,
        },
      }
    }
    case "field": {
      return {
        ...state,
        [field]: payload,
      }
    }

    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}
