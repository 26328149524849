import { DeploymentUnitOutlined, LineChartOutlined, UnorderedListOutlined } from "@ant-design/icons"
import { Button, Card, Col, Comment, Modal, Row, Space, Statistic, Typography } from "antd"
import React from "react"
import { Link, useParams } from "react-router-dom"
import { Hero } from "./Hero"
import { useTranslation } from "react-i18next"

export const AssessmentLimit = ({ visible, state, assessment, person }) => {
  const { enrolmentUid, enrolmentType } = useParams()
  const { t } = useTranslation()
  return (
    <Modal className="modal modal--noPadding" visible={visible} footer={null} closable={false}>
      <Hero>
        <Hero.Slide img="https://images.unsplash.com/photo-1644169276891-235775ba3cad?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80">
          <Comment
            author={<p style={{ color: "white", marginBottom: 0 }}>{t("Assessment")}</p>}
            // content={"Hello World"}
          />
          <Hero.Title level={2}>
            {t("Hey")}, {person?.firstName}
          </Hero.Title>
          <Hero.Description noEllipsis>{t("youHaveFailedThisAssessmentLimit")}</Hero.Description>
          <Space>
            <Link to={`/${enrolmentType}/${enrolmentUid}`}>
              <Button
                type="primary"
                onClick={() => {
                  window.location.reload()
                  // dispatch({
                  //   type: "next-attempt",
                  // })
                  // form.resetFields()
                }}
              >
                {t("reloadAssessment")}
              </Button>
            </Link>
            <Button
              type="primary"
              onClick={() => {
                window.location.pathname = `/${enrolmentType}/${enrolmentUid}`
              }}
            >
              {t("returnHome")}
            </Button>
          </Space>
        </Hero.Slide>
      </Hero>
      <div className="modal__body">
        <Row style={{ marginTop: "1.5rem" }} gutter={["16", "16"]}>
          <Col style={{ marginBottom: 16 }} xs={24} sm={12} lg={8}>
            <Card>
              <Statistic
                title={
                  <>
                    <DeploymentUnitOutlined size={12} /> Status
                  </>
                }
                value={state?.passed ? t("Passed") : t("Failed")}
                valueStyle={{
                  color: state?.passed ? "#3f8600" : "#cf1322",
                }}
              />
            </Card>
          </Col>
          <Col style={{ marginBottom: 16 }} xs={24} sm={12} lg={8}>
            <Card>
              <Statistic
                title={
                  <>
                    <UnorderedListOutlined size={12} /> {t("answeredCorrectly")}
                  </>
                }
                value={isNaN(state?.result) ? 0 : Number(state?.result)}
                valueStyle={{ color: "#335191" }}
                suffix={
                  <Typography.Text style={{ fontSize: 12 }} disabled>
                    / {state?.total}
                  </Typography.Text>
                }
              />
            </Card>
          </Col>
          <Col style={{ marginBottom: 16 }} xs={24} sm={12} lg={8}>
            <Card>
              <Statistic
                title={
                  <>
                    <LineChartOutlined size={12} /> {t("Result")} -{" "}
                    {assessment?.passScore && assessment?.passScore > 0 && `${t("Min")} (${assessment?.passScore}%)`}
                  </>
                }
                value={isNaN(state?.resultAsPercentage) ? 0 : Number(state?.resultAsPercentage)}
                valueStyle={{
                  color: state?.passed ? "#3f8600" : "#cf1322",
                }}
                suffix={
                  <Typography.Text style={{ fontSize: 12 }} disabled>
                    %
                  </Typography.Text>
                }
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}
